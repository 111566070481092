import React, { ReactElement } from 'react'

import carrierUtils from '@lib/carrier'
import { useTranslation } from '@lib/i18n'
import ConnectionBreakdown, { ConnectionBreakdownData } from '@pages/Checkout/BookingDetails/ConnectionBreakdown'
import useValidity from '@pages/Checkout/hooks/useValidity'

import '@pages/Checkout/BookingDetails/TripBreakdown/index.scss'

interface TripBreakdownProps {
  express?: boolean
  outbound: ConnectionBreakdownData | null
  inbound: ConnectionBreakdownData | null
  carriers?: MarketingCarrier[]
  isCarriersLoading: boolean
  fareClass?: string
}

const TripBreakdown = (props: TripBreakdownProps): ReactElement => {
  const { express, outbound, inbound, carriers = [], isCarriersLoading, fareClass } = props
  const { t } = useTranslation()
  const isTwoWayTrip = !!inbound
  const connectionBreakdownProps = {
    showTime: !express,
    showCarrier: !express && !!carriers.length,
    showDuration: express,
  }
  const { outboundValidity, inboundValidity } = useValidity({ fareClass, carriers, inbound, outbound })

  return (
    <div className="checkout-trip">
      {outbound && (
        <ConnectionBreakdown
          {...connectionBreakdownProps}
          connection={outbound}
          carriers={carrierUtils.getCarriers(outbound, carriers)}
          title={isTwoWayTrip ? t('checkout.bookingDetails.outboundTrip') : null}
          fareClass={fareClass}
          validity={outboundValidity}
          isLoading={isCarriersLoading}
        />
      )}
      {inbound && (
        <>
          <hr />
          <ConnectionBreakdown
            {...connectionBreakdownProps}
            connection={inbound}
            carriers={carrierUtils.getCarriers(inbound, carriers)}
            title={t('checkout.bookingDetails.returnTrip')}
            fareClass={fareClass}
            validity={inboundValidity}
            isLoading={isCarriersLoading}
          />
        </>
      )}
    </div>
  )
}

export default TripBreakdown
