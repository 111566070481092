import { eachHourOfInterval, endOfDay, startOfDay } from 'date-fns'
import { Field, FieldProps } from 'formik'
import React, { ReactElement } from 'react'

import date from '@lib/date'
import { useTranslation } from '@lib/i18n'
import { Dropdown } from '@ui'

const dayInterval = { start: startOfDay(new Date()), end: endOfDay(new Date()) }
const hoursInterval = eachHourOfInterval(dayInterval)

const timeOptions = hoursInterval.map(hour => ({
  value: date.formatTime(hour),
  selectedLabel: date.formatTime(hour),
}))

const DepartureTimeField = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <Field name="departureTime">
      {({ field: { value, name }, meta: { error, touched }, form: { setFieldValue } }: FieldProps) => (
        <Dropdown
          value={value}
          onChange={updatedValue => {
            setFieldValue(name, updatedValue)
          }}
          checkedMark
          label={t('searchBar.departureTimeLabel')}
          items={timeOptions}
          errorMessage={touched ? error : undefined}
        />
      )}
    </Field>
  )
}

export default DepartureTimeField
