import { useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import TripDirection, { DirectionType } from '@components/TripDirection'
import fareUtils from '@lib/fare'
import utils from '@lib/utils'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'

const DirectionField = (): ReactElement => {
  const [{ outbound }] = useCheckout()
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const { fareClass } = values

  const types: Record<string, FareJourneyType> = useMemo(
    () => ({
      [DirectionType.OneWay]: 'single',
      [DirectionType.Return]: 'open_return',
    }),
    [],
  )

  const tripValue = useMemo(() => {
    const type =
      fareUtils.getFareByCode(fareClass, outbound)?.fareClass.journeyType ?? /* istanbul ignore next */ 'single'

    return utils.object.switchKeyValue(types)[type]
  }, [fareClass, outbound, types])

  const onTripTypeChange = (value: DirectionType): void => {
    const fare = fareUtils.getByJourneyType(types[value], outbound)
    setFieldValue('fareClass', fare?.fareClass.code)
  }

  return <TripDirection layout="row" value={tripValue} onChange={onTripTypeChange} />
}

export default DirectionField
